.carousel {
    position: absolute;
    left: 0;
    top: 0;
    width: $col5;
    height: 100%;
    overflow: hidden;

    .owl-carousel {
        .owl-wrapper-outer {
            height: 100%;
        }

        .item {
            height: 100vh;

            img {
                display: block;
                width: auto;
                height: 100%;
            }

            .item-details {
                position: absolute;
                bottom: 35%;
                margin: 0 auto;
                width: $col10;
                height: auto;
                max-height: 500px !important;

                span.c-title {
                    position: relative;
                    top: 30%;
                    left: 10%;
                    font-family: $font2;
                    font-size: 100%;
                    color: $white;
                    z-index: 150;
                    padding: 5px;
                    border: 1px dashed white;
                    border-radius: 5px;
                    text-transform: uppercase;
                }

                h1 {
                    position: relative;
                    margin-top: 15px;
                    left: 10%;
                    font-family: $font3;
                    color: $white;
                    font-size: 3em;
                    z-index: 150;
                }

                p {
                    position: relative;
                    top: 75%;
                    left: 10%;
                    font-family: $font2;
                    color: $white;
                    font-size: 1em;
                    z-index: 150;
                    width: $col10;
                }

                z-index:999;

                hr {
                    margin-top: -20px;
                    @extend %left;
                }

                .pagination {
                    position: relative;
                    left: 10%;
                    float: left;
                    margin-top: 30px;
                    margin-right: 10px;

                    img {
                        width: 20px;
                    }
                }
            }
        }

        .owl-controls {
            position: absolute;
            left: 30px !important;
            bottom: 280px;

            .owl-next,
            .owl-prev {
                img {
                    width: 20px;
                }
            }

            .owl-prev {
                margin-left: 15px;
            }

            .owl-pagination {
                display: none;
            }
        }
    }
}

.carousel-projects {
    position: relative;
    left: 0;
    top: 0;
    width: $col11;
    height: auto;
    overflow: hidden;

    .owl-carousel {
        .owl-wrapper-outer {
            height: 100%;
        }

        .item {
            width: 100%;
            height: 100%;

            img {
                display: block;
                width: auto;
                height: 100%;
            }
        }

        .owl-controls {
            position: absolute;
            left: 25px !important;

            .owl-next,
            .owl-prev {
                img {
                    width: 20px;
                }
            }

            .owl-prev {
                margin-left: 15px;
            }

            .owl-pagination {
                display: none;
            }
        }
    }
}