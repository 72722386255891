@keyframes kenburns {
    0% {
        opacity: 0;
    }
    5% {
        opacity: 1;
    }
    95% {
        transform: scale3d(1, 1, 1) translate3d(20%, 20%, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }
    100% {
        transform: scale3d(1.5, 1.5, 1.5) translate3d(25%, 25%, 0px);
        opacity: 0;
    }
}

@keyframes move {
    from {
        transform: scale(1.1);
        -ms-transform: scale(1.1);
        /* IE 9 */
        -webkit-transform: scale(1.1);
        /* Safari and Chrome */
        -o-transform: scale(1.1);
        /* Opera */
        -moz-transform: scale(1.1);
    }
    to {
        transform: scale(1.0);
        -ms-transform: scale(1.0);
        /* IE 9 */
        -webkit-transform: scale(1.0);
        /* Safari and Chrome */
        -o-transform: scale(1.0);
        /* Opera */
        -moz-transform: scale(1.0);
        /* Firefox */
    }
}

@keyframes TopToBottom {
    0% {
        bottom: 0px
    }
    100% {
        bottom: 50px;
    }
}
