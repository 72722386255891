// DIDOT
@font-face {
    font-family: 'Didot';
    src: url('../fonts/theanodidot-regular-webfont.eot');
    src: url('../fonts/theanodidot-regular-webfont.eot?#iefix') format('embedded-opentype'), 
    url('../fonts/theanodidot-regular-webfont.woff2') format('woff2'), 
    url('../fonts/theanodidot-regular-webfont.woff') format('woff'), 
    url('../fonts/theanodidot-regular-webfont.ttf') format('truetype'), 
    url('../fonts/theanodidot-regular-webfont.svg#theano_didotregular') format('svg');
    font-weight: normal;
    font-style: normal;
}


/*====================#
#   font-set
======================*/
$font1 : 'proxima-nova', sans_serif;
$font2 : 'din-condensed-web', sans_serif;
$font3 : 'Didot', sans_serif;