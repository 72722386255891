// -----------------------------------
// GRID SYSTEM
// -----------------------------------
$col1: 80 / 960*100%;
$col2: 160 / 960*100%;
$col3: 240 / 960*100%;
$col4: 320 / 960*100%;
$col5: 400 / 960*100%;
$col6: 480 / 960*100%;
$col7: 560 / 960*100%;
$col8: 640 / 960*100%;
$col9: 720 / 960*100%;
$col10: 800 / 960*100%;
$col11: 880 / 960*100%;
$col12: 960 / 960*100%;
.col1 {
    width: 80 / 960*100%;
}

.col2 {
    margin: 0 auto;
    width: 160 / 960*100%;
}

.col3 {
    margin: 0 auto;
    width: 240 / 960*100%;
}

.col4 {
    margin: 0 auto;
    width: 320 / 960*100%;
}

.col5 {
    margin: 0 auto;
    width: 400 / 960*100%;
}

.col6 {
    width: 480 / 960*100%;
}

.col7 {
    width: 560 / 960*100%;
}

.col8 {
    width: 640 / 960*100%;
}

.col9 {
    margin: 0 auto;
    width: 720 / 960*100%;
}

.col10 {
    margin: 0 auto;
    width: 800/ 960*100%;
}

.col11 {
    width: 880 / 960*100%;
}

.col12 {
    margin: 0 auto;
    width: 960 / 960*100%;
}
