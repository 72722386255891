// -----------------------------------
// ERAK DENIM CO LTD
// Author : Levent Kaya 31aug.com
// -----------------------------------
@import '_normalize';
@import '_fonts';
@import '_grid';
@import '_navbar';
@import '_webkits';
@import '_colors';
@import '_backgrounds';
@import '_buttons';
@import '_carousel';
@import '_hover';
@import '_position';
@import '_input';
@import '_animation';
@import '_chocolat';
@import 'fontawesome/font-awesome';
@import '_responsive';

* {
    -webkit-touch-callout: none;
    -webkit-text-size-adjust: none;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    -webkit-user-drag: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: normal;
}

html,
body {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    font-family: $font2;
    font-weight: 500;
    background-color: $mainBg;
    overflow: hidden;

}

body.lock {
    height: 100%;
    // overflow: hidden;
}

// -----------------------------------
// UI + SEP.
// -----------------------------------
ul {
    list-style: none;

    a,
    a:visited,
    a:link,
    a:hover {
        text-decoration: none;
    }

    padding:0;
    margin:0;
}

.seperator {
    padding: 2% 0px 2% 0px;
}

.clearfix {
    clear: both;
}

.overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: $mainBg;
    opacity: 0.6;
    z-index: 70;
}

.carousel-overlay {
    position: absolute;
    // top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0.2), $mainBg);
    background-image: linear-gradient(rgba(255, 255, 255, .2), $mainBg);
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
}

.bg {
    position: fixed;
    width: $fullW;
    height: $fullH;
    background-position: 50% 50%;
    background-size: cover;
    overflow: hidden;
    transform: translate3d(0, 0, 0) scale(1.50);
    // z-index:1;
}

.loaded .bg {
    transform: scale(1);
    transition: 45s transform;
}

.small-hr {
    width: 50px;
    color: white !important;
}

// -----------------------------------
// WRAPPER
// -----------------------------------
#wrapper {
    width: 100%;
    height: 100%;
    // overflow: auto;
}

// -----------------------------------
// HEADER
// -----------------------------------
#header {
    position: relative;
    width: $fullW;
    z-index: 10;
    height: 80px;
    padding: 1% 2% 1% 2%;

    .logo-big {
        position: relative;
        max-width: 200px;
        @extend %left;
        padding: 1%;

        img {
            width: 160px;
            max-width: 200px;
            height: auto;
        }
    }

    .navigation-top {
        position: relative;
        height: 40px;
        // max-width:750px;
        padding: 40px 40px;
        right: 0px;
        @extend %right;
    }
}

#sidebar {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 100%;
    @extend %right;
    background-color: rgba(7, 17, 28, 0.9);
    z-index: 100;

    .sidebar-right {
        position: fixed;
        bottom: 35px;

        ul {
            width: 100%;

            li {
                text-align: center;
                width: 50px;
                display: block;
                line-height: 2.2em;
                color: rgb(255, 255, 255);
            }

            li a:link,
            li a:active,
            li a:visited {
                color: rgb(255, 255, 255);
            }

            li a:hover {
                color: #d8710e;
            }

            li:nth-child(1) {
                margin-bottom: 20px;
            }
        }
    }
}

#legals {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 40%;
    z-index: 999;

    .legals-container {
        width: 100%;
        margin-left: 50px;

        ul {
            list-style: none;

            li {
                float: left;
                margin-right: 10px;

                a.inline-link {
                    padding: 5px 12px;
                    background: none;
                    color: white;

                    &:hover {
                        background: white;
                        color: $headerblue;
                    }
                }
            }
        }
    }
}

// -----------------------------------
// LANDING SLIDER
// -----------------------------------
#video {
    width: 100vw;
    height: 100vh
}

#landing-slider {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    overflow: hidden;
    clear: both;

    .swiper-container {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        @extend %center;

        .swiper-slide {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            font-size: 18px;
            overflow: hidden;
            @extend %center;

            img {
                position: relative;
                width: 100%;
                height: auto;
                z-index: 50;
            }

            .title,
            .subtitle {
                position: absolute;
                left: 51%;
                top: 35%;
                max-width: 600px;
                height: auto;
                font-size: 3em;
                font-family: $font3;
                transform: translateX(-50%);
                color: #fff;
                text-align: center;

                h4 {
                    font-size: 24px;
                }

                hr {
                    width: 50px;
                    color: white;
                }

                z-index:100;
            }

            .subtitle {
                margin-top: 180px;
                display: inline-block;
                font-family: $font2;
                font-size: 1.1em;
                color: $white;
            }
        }

        .swiper-pagination-bullet {
            width: 15px;
            height: 15px;
            opacity: 1;
            background: $mainBg;
            border-spacing: 1% 2em;
            margin-bottom: 30px;
        }

        .swiper-pagination-bullet-active {
            width: 15px;
            height: 15px;
            opacity: 1;
            background: $white;
            border: 2px inset $mainBg;
            border-spacing: 1% 2em;
            margin-bottom: 30px;
        }
    }
}

a.inline-link {
    display: inline-block;
    color: $mainBg;
    text-decoration: none;
    padding: 2% 8%;
    background: white;
    font-size: .9em;
    border-radius: 5px;
    text-transform: uppercase;

    &:hover {
        color: white;
        background: $secondBg;
    }
}

// -----------------------------------
// ABOUT US
// -----------------------------------
#about-us {
    position: absolute;
    width: $col12;
    height: $fullH !important;
    top: 0;
    padding: 0;
    margin: 0;

    .content-s1 {
        position: absolute;
        width: $col7;
        top: 0;
        right: 0px;
        height: 100%;
        margin: 0 auto;

        .container {
            padding: 50% 10% 10% 10%;
            transform: translateY(-20%);

            .title {
                img {
                    width: 150px;
                }
            }

            .subtitle {
                color: white;
                margin-top: 10px;
                font-style: italic;
                font-size: 1.1em;
                text-transform: uppercase;
                font-family: $font2;
            }

            hr {
                margin: 25px 0 25px -35px;
            }

            .description {
                color: white;
                font-size: 1.1em;
                line-height: 1.5em;
            }

            .media-gallery-widget {
                width: 230px;
                height: 50px;
                background: $mainBg;
                border-radius: 10px;
                margin-top: 50px;

                nav a {
                    position: relative;
                    display: inline-block;
                    margin: 15px 45px;
                    outline: none;
                    color: #4e97ea;
                    text-decoration: none;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-weight: 400;
                    font-size: 1em;
                    float: left;

                    i {
                        margin-right: 5px
                    }
                }

                nav a:hover,
                nav a:focus {
                    outline: none;
                    color: $secondBg;
                }
            }
        }
    }
}


/* CONTENT WRAPPER  */

%content-wrapper {
    position: absolute;
    width: $col12;
    height: $fullH;
    top: 0;
    left: 0;

    .mini-content {
        position: absolute;
        width: $col6;
        height: $fullH;
        padding-left: 3%;
        padding-right: 3%;
        top: 50%;
        transform: translateY(-12%);

        .subtitle {
            display: block;
            font-family: $font2;
            color: $secondBg;
            text-transform: uppercase;
            font-size: 1.1em;
        }

        .title {
            width: 100%;
            display: block;
            font-family: $font3;
            color: white;
            font-size: 3em;
            margin-bottom: 30px;
        }

        
        .description {
            display: block;
            width: $col7;
            color: white;
            font-size: 1em;
            line-height: 1.5em;
        }

        hr {
            display: block;
            width: 100px;
            margin-left: -50px;
            float: left;
            margin-bottom: 20px;

            .white {
                color: white;
            }
        }

        div {
            width: $col4;
        }

        @extend %left;
    }

    .actual-content {
        position: absolute;
        width: $col7;
        height: 500px;
        top: 47%;
        right: 0;
        transform: translateY(-40%);
    }
}

// -----------------------------------
// CERTIFICATES
// -----------------------------------
#certificates {
    position: absolute;
    width: $fullW;
    height: $fullH;
    overflow: hidden;
    top: 0;
    @extend %content-wrapper;

    .mini-content {
        top:30%;
    }

    .actual-content {
        // padding: 0 5% 4% 0;
        // margin-top: -70px;
        right:50px;
        
        ul {
            li {
                width: 20%;
                height: 160px;
                // padding: 0 30px;
                @extend %left;

                img {
                    max-width: 120px;
                    max-height: 120px;
                }

                span {
                    color: white;
                    padding: 20px;
                    text-align: center;
                    display: block;
                }

                h4 {
                    color: white;
                    margin-top: 0px;
                    display: block;
                    text-align: center;
                }
            }

            li:hover {
                -webkit-transform: scale(1.1, 1.1);
                transform: scale(1.1, 1.1);
            }
        }
    }
}

// -----------------------------------
// PROJECTS
// -----------------------------------
#projects {
    @extend %content-wrapper;
    overflow: hidden;

    .actual-content {
        width: $col7;
        padding: 0px 6% 5% 0;

        .title {
            font-size: .8em;
            font-family: $font3;
            color: white;
            line-height: 10px;

            span {
                color: $secondBg;
                font-family: $font2;
                font-size: 1em;
            }

            margin-bottom:20px;
        }

        .lightbox {
            overflow: hidden;
            width: $col12;

            img {
                width: $col12;
            }

            margin-bottom:20px;

        }

        .description {
            font-size: 1.1em;
            color: white;

            p {
                text-align: left;
                float: left;
                width: $col10;
                margin-top: -3px;
            }

            i {
                float: left;
                font-size: 1em;
                color: $secondBg;
                padding-left: 25px;
            }
        }
    }
}

// -----------------------------------
// FACILITIES
// -----------------------------------
#facilities {
    position: absolute;
    width: $col12;
    height: $fullH !important;
    top: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;

    .content-s2 {
        position: absolute;
        width: $col7;
        top: 0;
        right: 0px;
        height: 100%;
        margin: 0 auto;

        .container {
            padding: 41% 10% 10% 10%;
            transform: translateY(-20%);

            .title {
                text-align: center;
                font-familiy: $font1;
                font-size: 3em;
                color: white;

                img {
                    width: 150px;
                }
            }

            .subtitle-small {
                color: $secondBg;
                margin-top: 10px;
                font-size: 1em;
                text-transform: uppercase;
                text-align: center;
                font-family: $font2;
            }

            hr {
                @extend %center;
                margin-top: 5%;
                margin-bottom: 2%;
            }

            .description {
                width: $col12;
                color: white;
                font-size: 1.1em;
                line-height: 1.5em;
            }

            .media-gallery-widget {
                width: $col12;
                height: 50px;
                background: $mainBg;
                border-radius: 10px;
                margin-top: 50px;

                nav a {
                    position: relative;
                    display: inline-block;
                    margin: 15px 25px;
                    outline: none;
                    color: #4e97ea;
                    text-decoration: none;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-weight: 400;
                    font-size: 1em;
                    float: left;

                    i {
                        margin-right: 5px
                    }
                }

                nav a:hover,
                nav a:focus {
                    outline: none;
                    color: $secondBg;
                }
            }

            .counter {
                width: $col12;
                @extend %center;

                .circle {
                    border: 2px dashed white;
                    width: 120px;
                    height: 120px;
                    border-radius: 100px;
                    padding: 50px;
                    color: white;
                    background: rgba(24, 33, 42, .4);
                    margin-right: 50px;
                    text-align: center;

                    span {
                        width: 100px;
                        display: block;
                        font-size: 2em;
                        color: white;
                        text-align: center;
                        margin-left: -40px;
                        margin-top: -10px;
                    }

                    h3 {
                        width: 100px;
                        margin-top: 60px;
                        margin-left: -40px;
                        text-align: center;
                    }
                }
            }
        }
    }
}

// -----------------------------------
// SUSTAINABILITY
// -----------------------------------
#sustainability {
    @extend %content-wrapper;
    overflow: hidden;

    .actual-content {
        padding: 6% 6% 5% 0;

        .title {
            font-size: 2em;
            font-family: $font3;
            color: white;
        }

        .lightbox {
            overflow: hidden;
            width: $col12;

            img {
                width: $col12;
            }

            margin-bottom:20px;
        }

        .description {
            font-size: 1.1em;
            color: white;

            p {
                text-align: left;
                float: right;
                width: $col11;
                margin-top: -3px;
            }

            i {
                float: left;
                font-size: 1em;
                color: $secondBg;
                padding-left: 25px;
            }
        }
    }
}

// -----------------------------------
// SERVICES
// -----------------------------------
#services {
    @extend %content-wrapper;
    overflow: hidden;

    .actual-content {
        width: $col8;
        padding: 3% 3% 5% 0;
        margin-top: -20px;

        .strp-caption {
            font-family: $font3 !important;
        }
    }
}

// -----------------------------------
// COLLECTIONS
// -----------------------------------
#collections {
    @extend %content-wrapper;

    .actual-content {
        width: $col8;
        top: 50%;
        left: 50%;

        .carousel {
            width: $col8;
            height: auto;

            .item {
                position: relative;

                img {
                    width: 100%;
                    height: auto;
                }

                .item-details {
                    position: relative;
                    width: 100%;
                    float: left;
                    bottom: -20px;

                    h1,
                    span,
                    p {
                        left: 10px;
                    }

                    p {
                        top: 40px;
                    }

                    .download {
                        float: right;

                        img {
                            width: 50px;
                            height: auto;
                        }

                        img:hover {
                            opacity: .8;
                        }
                    }
                }
            }

            .owl-controls {
                visibility: hidden;
                display: none;
            }
        }
    }
}

#hr {
    position: absolute;
    width: $col12;
    height: $fullH !important;
    top: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;

    .content-s3 {
        position: absolute;
        width: $col7;
        top: 0;
        right: 0px;
        height: 100%;
        margin: 0 auto;
        overflow: auto;

        .container {
            position: relative;
            padding: 30% 10% 10% 10%;
            width: 100%;

            span.alert {
                color: red;
            }

            .title {
                text-align: center;
                font-familiy: $font1;
                font-size: 3em;
                color: white;

                img {
                    width: 150px;
                }
            }

            .subtitle-small {
                color: $secondBg;
                margin-top: 10px;
                font-size: 1em;
                text-transform: uppercase;
                text-align: center;
                font-family: $font2;
            }

            hr {
                @extend %center;
                margin-top: 5%;
                margin-bottom: 2%;
            }

            .ik-buttons {
                position: absolute;
                top: 480px;
                width: $col9;
                margin-left: 15px;
            }

            .checkbox_container {
                display: block;
                position: relative;
                padding-left: 35px;
                margin-bottom: 12px;
                cursor: pointer;
                font-size: 16px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;

                a:link,
                a:visited {
                    text-decoration: none;
                    color: #d8710e;
                    ;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .checkbox_container input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 20px;
                width: 20px;
                background-color: #eee;
            }

            .checkbox_container:hover input~.checkmark {
                background-color: #ccc;
            }

            .checkbox_container input:checked~.checkmark {
                background-color: #d8710e;
            }

            .checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }

            .checkbox_container input:checked~.checkmark:after {
                display: block;
            }

            .checkbox_container .checkmark:after {
                left: 6px;
                top: 3px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }

            .button-effect {

                a:link,
                a:visited,
                a:active {
                    text-decoration: none;
                    color: #fff;
                    padding: 2% 2%;
                    margin-top: 10px;
                }

                a:hover {
                    background: $secondBg;
                    transition: all .1s ease-out;
                }

                .send_button {
                    padding: 10px 10px;
                    border: 1px solid #fff;
                    display: block;
                    text-align: center;
                    transition: all .1s ease-out;
                }
            }

            #loading {
                border: 1px solid #fff;
                display: block;
                text-align: center;
                transition: all .1s ease-out;
            }

            #form_message {
                margin-top: -5px;
                padding: 12px 12px;
                border: 1px solid green;
                display: block;
                text-align: center;
            }

            svg path,
            svg rect {
                fill: #FF6700;
            }

            #intern-form,
            #job-form {
                width: $col9;
                position: absolute;
                display: flex;
                justify-content: center;
                margin-left: 10px;
            }
        }
    }
}


// -----------------------------------
// CONTACT
// -----------------------------------
#contact {
    position: absolute;
    width: $col12;
    height: $fullH !important;
    top: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;

    .content-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 10% 12%;
        top: 0;
        left: -1%;

        .title {
            h1 {
                display: block;
                margin: 0 auto;
                text-align: center;
                font-size: 3em;
                color: white;
                font-family: $font3;
            }

            h2 {
                display: block;
                margin: 0 auto;
                text-align: center;
                font-size: 1.3em;
                color: $secondBg;
                text-transform: uppercase;
            }

            hr {
                margin-top: 30px;
            }
        }

        .additional-info {
            position: relative;
            left: 49%;
            width: $col2;
            text-align: center;
            -webkit-transform: translateX(-25%);
            -moz-transform: translateX(-25%);
            -ms-transform: translateX(-25%);
            -o-transform: translateX(-25%);
            transform: translateX(-25%);
            // top: 50%;

            img {
                margin-left: 10px;
            }

            h2 {
                margin-left: -35px;
                color: white;
                font-size: 1.2em;
            }

            span a {
                color: white;
                text-decoration: none;
                font-size: 1.3em;

                &.contact-button {
                    padding: 10px;
                    background: white;
                    color: $mainBg;
                    border-radius: 5px;
                    @extend %center;
                    margin-left: -35px;
                    margin-bottom: 10px;

                    &:hover {
                        background: $secondBg;
                        color: white;
                    }
                }
            }
        }

        .content {
            position: relative;
            top: calc(100%/12);
            width: $col6;
            height: auto;

            h2 {
                font-size: 1.5em;
                color: $secondBg;
                text-align: center;
            }

            .seperator {
                width: $col6;
            }

            .information {
                text-align: center;
                font-size: 1.3em;
                color: white;
            }
        }
    }
}


/**=====================================
* Animate
**=====================================*/

.animate {
    -webkit-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
}

.animate-f {
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
}

.animate-s {
    -webkit-transition: all 1s ease-out;
    transition: all 1s ease-out;
}

@media only screen and(min-width: 300px) and(max-width:450px) {
    #header .navigation-top {
        position: relative;
        height: 40px;
        padding: 0 !important;
        right: -20px;
    }

    #facilities {
        overflow: auto;
    }

    #landing-slider {
        .title {
            font-size: 14px;
        }

        .subtitle {
            display: none;
        }
    }

    #about-us,
    #facilities {
        .carousel {
            width: $col12;
            top: 0;
            float: left;
        }

        .content-s1,
        .content-s2 {
            width: $col12;
            height: 100%;
            margin: 0 auto;
            float: left;
            left: 0;
            top: 700px !important;
        }

        .content-s1 .container,
        .content-s2 .container {
            position: relative;
            width: 100%;
            left: 0px;
        }
    }

    #facilities {

        .title,
        .subtitle,
        .description,
        .small-hr,
        .counter {
            visibility: visible !important;
        }
    }

    #about-us .item-details,
    #facilities .item-details {
        bottom: 50%;
    }

    #about-us .owl-controls,
    #facilities .owl-controls {
        left: 0px !important;
        bottom: 450px;
    }

    #sidebar {
        display: none;
    }

    #certificates,
    #sustainability,
    #projects,
    #services,
    #collections,
        {
        overflow: scroll;

        .content-wrapper {
            .mini-content {
                top: 40%;
                width: 100%;

                .title {
                    font-size: 2.5em;
                }

                .description {
                    width: 100%;
                    display: block;
                    float: left;
                }

                p {
                    margin-top: 150px;
                }
            }
        }

        .actual-content {
            padding: 20px !important;
            position: relative;
            left: 0;
            width: 100%;
            height: 200px;
            top: 530px;

            ul {
                li {
                    width: 33.3333%;
                    float: left;
                    padding: 0 !important;
                    display: inline-block;

                    img {
                        width: 80px;
                        height: auto;
                    }
                }
            }

            .lightbox .carousel-projects .owl-carousel {
                visibility: visible !important;
            }
        }
    }

    #sustainability {
        .actual-content {
            margin-top: 80px;
            height: auto;

            .lightbox {
                img {
                    // margin-top: 80px;
                    width: 150% !important;
                    height: auto;
                    margin-left: -45px;
                }
            }

            .description {
                margin-top: 20px;
                margin-left: -40px;
                padding-bottom: 100px;

                i {
                    display: none;
                }
            }
        }
    }

    #projects {
        .actual-content {

            // height:auto;
            h1 {
                line-height: 30px;
            }

            .description {
                width: 95%;
                visibility: visible !important;
                padding-bottom: 100px !important;
            }
        }
    }

    #services {
        .actual-content {
            width: 100%;

            .grid {
                figure {
                    min-width: 100%;
                    max-width: 100%;
                    max-height: 160px;
                    visibility: visible !important;
                }

                figure.effect-roxy figcaption {
                    margin-left: 5px;
                    text-align: left;
                    padding: 15px 0;
                    // font-size: 12px;
                }
            }
        }
    }

    #collections {
        .actual-content {
            margin-top: -30px;
            margin-left: -30px;

            .collection-wrapper .image img {
                width: 95%;
                height: auto;
            }

            .collection-wrapper {
                .content {
                    width: 100%;
                    left: 50%;
                    top: 200px;
                    transform: translateX(-50%);

                    h3 {
                        margin-left: -50px;
                    }

                    h4 {
                        display: none;
                    }

                    hr {
                        display: none;
                    }
                }
            }
        }
    }

    #hr {
        overflow: scroll;

        .title {
            margin-top: 100px !important;

            .small-hr {
                display: none;
            }
        }



        .apply-form {
            .attention {
                left: 50%;
                text-align: center;

                span {
                    margin-bottom: 10px;
                    width: 100% !important;
                    margin-left: -10px !important;
                }

                .kariyer-link {
                    margin-top: 30px !important;
                }

                .send_button {}
            }
        }
    }

    #contact {
        overflow: scroll;

        .content-wrapper .title {
            margin-top: 50px;
        }

        .content-wrapper {
            .content {
                width: 100%;
            }

            .additional-info {
                position: relative;
                left: 49%;
                -webkit-transform: translateX(-25%);
                transform: translateX(-25%);
                bottom: 0;
                top: 50px;

                span {
                    a.contact-button {
                        margin-left: -155px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width:450px) and (max-width:768px) {

    #about-us .item-details,
    #facilities .item-details {
        bottom: 45%;
    }

    #about-us .owl-controls,
    #facilities .owl-controls {
        left: 0px !important;
        bottom: 400px;
    }

    #about-us .content-s1 .container,
    #facilities .content-s2 .container {
        font-size: 1em;
        overflow: hidden;
    }
}

@media only screen and(min-width: 768px) and (max-width: 1000px) {
    #certificates {
        .actual-content {
            margin-top: 80px;
            margin-right: 60px;

            ul {
                li {
                    img {
                        width: 120px;
                        height: auto;
                    }
                }
            }
        }
    }

    #sustainability {
        .actual-content {
            margin-top: 50px;
            height: auto;
            width: 50%;
            margin-right: 10px;

            .lightbox {
                margin-left: -10px;

                img {
                    width: 110% !important;
                    height: auto;
                    // margin-left: -20px!important;
                }
            }

            .description {
                margin-top: 10px;
                margin-left: -40px;
                padding-bottom: 100px;

                i {
                    display: none;
                }
            }
        }
    }

    #projects {
        .actual-content {
            margin-top: 40px;

            h1 {
                line-height: 30px;
            }

            .description {
                width: 90%;
            }
        }
    }

    #services {
        .actual-content {
            width: 60%;
            margin-right: 50px;
            margin-top: -60px;

            .grid {
                figure {
                    min-width: 100px;
                    max-width: 100px;
                    max-height: auto;
                    visibility: visible !important;
                }

                figure.effect-roxy figcaption {
                    margin-left: 5px;
                    text-align: left;
                    padding: 50% 0;
                    font-size: 12px;
                }
            }
        }
    }

    #collections {
        .actual-content {
            margin-top: 120px;

            .collection-wrapper .image img {
                width: 85%;
                height: auto;
            }

            .collection-wrapper {
                .content {
                    width: 100%;
                    left: 50%;
                    top: 200px;
                    transform: translateX(-50%);

                    .description {
                        h3 {
                            // margin-top: -15px;
                            margin-left: -150px;
                        }

                        h4 {
                            display: none;
                        }

                        hr {
                            display: none;
                        }

                        .download {
                            margin-left: 20px;
                        }
                    }
                }
            }
        }
    }

    #hr {
        overflow: scroll;

        .title {
            margin-top: 120px !important;

            .small-hr {
                display: none;
            }
        }



        .apply-form {
            .attention {
                left: 50%;
                text-align: center;

                span {
                    margin-bottom: 10px;
                    width: 100% !important;
                    margin-left: -10px !important;
                }

                .kariyer-link {
                    margin-top: 30px !important;
                }

                .send_button {}
            }
        }
    }

    #contact {
        overflow: scroll;

        .content-wrapper .title {
            margin-top: 50px;
        }

        .content-wrapper {
            .content {
                width: 100%;
            }

            .additional-info {
                position: relative;
                width: 30%;
                padding: 0;
                margin: 0;
                top: 50px;
                margin-bottom: 50px;

                span {
                    a.contact-button {
                        margin-left: -55px;
                    }
                }
            }
        }
    }

    #sidebar {
        display: none;
    }
}

@media only screen and(min-width: 1000px) and (max-width: 1024px) {

    #about-us .content-s1 .container,
    #facilities .content-s2 .container {
        padding: 70% 10% 10% 10% !important;
        height: 100%;
    }

    #hr {
        overflow: scroll;
    }

    #contact {
        overflow: scroll;

        .content-wrapper .title {
            margin-top: -50px;
        }

        .content-wrapper {
            .content {
                width: 50%;
            }

            .additional-info {}
        }
    }

    #sidebar {
        display: none;
    }

    // #facilities .content-s2 .container {
    //     width: $col11;
    //     height: 100%;
    //     padding: 60% 10% 10% 10%!important;
    // }
}

@media only screen and (max-width:1024px) {
    #header {

        nav.turkish,
        nav.english {
            display: none;
        }

        #menu {
            padding: 5%;
            position: absolute;
            z-index: 999;
            right: 0;
            top: 0;
        }

        #menu a:link,
        #menu a:visited,
        #menu a:active,
            {
            color: #fff;
            text-decoration: none;
            font-size: 2em;
        }

        #menu a:hover {
            color: $secondBg;
        }
    }

    #menu-responsive {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        hight: 100%;
    }

    #menu-responsive,
    #menu-responsive a:link,
    #menu-responsive a:active,
    #menu-responsive a:visited {
        color: white;
        text-transform: uppercase;
        position: absolute;
        color: #fff;
        text-decoration: none;
        font-size: 1em;
    }

    #menu-responsive ul {
        background-color: $mainBg;
        padding: 40px;
        height: 740px;
        overflow: hidden !important;
    }

    #menu-responsive ul li {
        padding: 22px;
        left: 0;
        height: 30px;

        ul {
            li {
                left: -30px;
            }
        }
    }

    #menu-responsive.hamburger-menu {
        display: none;
    }
}

@media only screen and(min-width: 1025px) {
    #menu-responsive {
        display: none;
    }

    .hamburger-menu {
        display: none;
    }

    #menu {
        display: none;
    }

    #about-us,
    #certificates {
        overflow: hidden;
    }
}