// -----------------------------------
// COLOR SETTINGS
// -----------------------------------
$mainBg:#0e1722;
$secondBg:#d8710e;
$lightgrey: #e1e1e1;
$grey: #f0f0f0;
$red:#c22026;
$white:#fff;
$black:#000;
// corporate
$h1blue: #1d274a;
// additional contents
$addbg: #00cccc;
$tblue: #164FA8;
$footerbg: #f8f8f8;
$headerblue: #052a3f;

// sub gallery
$gallerybg: #0b2b44;