/* All */

.nav {
    // margin-bottom: 48px;
}

.nav ul {
    *zoom: 1;
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav ul:before,
.nav ul:after {
    content: "";
    display: table;
}

.nav ul:after {
    clear: both;
}

.nav ul > li {
    float: left;
    position: relative;
}

.nav a {
    display: block;
    padding: 10px 20px;
    line-height: 1.2em;
    font-size: 1.1em;
    color: #fff;
    text-transform: uppercase!important;
}

.nav a:hover {
    text-decoration: none;
    background: white;
    color: #0e1722;

}

.nav li ul {
    background: white;
}

.nav li ul li {
    font-size: .9em;
    width: 200px;
    color: #f4f4f4;
}

.nav li ul a {
    border: none;
    background: white;
    color: #0e1722;
}

.nav li ul a:hover {
    color: #d8710e;
    background: #f4f4f4;
}


/* Not Animated
============================== */

.nav1 ul > li:hover ul {
    left: 0;

}

.nav1 li ul {
    position: absolute;
    left: -9999em;
    top: 36px;
    z-index: 1;
}


/* Fade In and Out
============================== */

.nav2 ul > li:hover ul {
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
        animation-name: TopToBottom;
    animation-duration: 4s;
}

.nav2 li ul {
    position: absolute;
    left: 0;
    top: 36px;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: 200ms ease;
    -moz-transition: 200ms ease;
    -o-transition: 200ms ease;
    transition: 200ms ease;
}


/* Slide Down
============================== */

.nav3 ul > li:hover ul li {
    height: 36px;
}

.nav3 li ul {
    position: absolute;
    left: 0;
    top: 36px;
    z-index: 1;
}

.nav3 li ul li {
    overflow: hidden;
    height: 0;
    -webkit-transition: height 200ms ease-in;
    -moz-transition: height 200ms ease-in;
    -o-transition: height 200ms ease-in;
    transition: height 200ms ease-in;
}


/* Slide Down 2
============================== */

.nav4 ul > li:hover ul li {
    max-height: 150px;
}

.nav4 li ul {
    position: absolute;
    left: 0;
    top: 36px;
    z-index: 1;
}

.nav4 li ul li {
    overflow: hidden;
    max-height: 0;
    -webkit-transition: max-height 500ms ease;
    -moz-transition: max-height 500ms ease;
    -o-transition: max-height 500ms ease;
    transition: max-height 500ms ease;

}


/* Fold Out
============================== */

.nav5 ul > li:hover ul {
    max-height: 1000px;
    -webkit-transform: perspective(400) rotate3d(0, 0, 0, 0);
}

.nav5 li ul {
    position: absolute;
    left: 0;
    top: 41px;
    z-index: 1;
    max-height: 0;
    overflow: hidden;
    -webkit-transform: perspective(400) rotate3d(1, 0, 0, -90deg);
    -webkit-transform-origin: 50% 0;
    -webkit-transition: 350ms;
    -moz-transition: 350ms;
    -o-transition: 350ms;
    transition: 350ms;
}

.nav.hidden {
    display: none;
}
