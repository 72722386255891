.left {
    float: left;
}

%left {
    float: left
}

.right {
    float: right;
}

%right {
    float: right
}

@mixin vAlign($amount) {
    -webkit-transform: translateY($amount);
    -webkit-transform: translateY($amount);
    -ms-transform: translateY($amount);
    transform: translateY($amount);
}

@mixin hAlign($amount) {
    -webkit-transform: translateX($amount);
    -ms-transform: translateX($amount);
    transform: translateX($amount);
}

%Middle {
    vertical-align: middle;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
}

$fullW: 100%;
$fullH: 100%;
%center {
    display: flex;
    justify-content: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}
